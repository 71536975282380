body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", 'Roboto', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#logoMenu {
  width: auto;
}

.app-container {
  flex: 1 1 auto;
  max-width: 100%;
  transition: margin-left 0.3s, max-width 0.3s;
  will-change: margin-left;
  will-change: max-width;
}

#main-nav.mdc-drawer--dismissible {
  position: fixed;
  top: 64px;
  left: 0;
  height: calc(100vh - 64px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: auto;
}

#main_section.mdc-top-app-bar__section--align-start {
  flex: 20;
}

#main_section .mdc-top-app-bar__title {
  margin: 0 auto;
}

.mapContainer {
  height: calc(100vh - 64px);
}

#main-nav-drawer {
  opacity: 1;
}

.groupMenuDiv {
  margin-left: 14px !important;
  margin-right: 14px !important;
  border-color: #004691 !important;
}

.clientColor {
  color: #004691 !important;
}

.groupHeader {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
}

.mdc-list-item {
  font-family: "Open Sans", 'Roboto', serif !important;
}

.mdc-list-item.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.trackHistoryMenuDiv {
  margin-left: 14px !important;
  margin-right: 14px !important;
  border-color: rgba(0, 0, 0, 0.2) !important;
}
