body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", 'Roboto', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#logoMenu {
  width: auto;
}

.app-container {
  flex: 1 1 auto;
  max-width: 100%;
  -webkit-transition: margin-left 0.3s, max-width 0.3s;
  transition: margin-left 0.3s, max-width 0.3s;
  will-change: margin-left;
  will-change: max-width;
}

#main-nav.mdc-drawer--dismissible {
  position: fixed;
  top: 64px;
  left: 0;
  height: calc(100vh - 64px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: auto;
}

#main_section.mdc-top-app-bar__section--align-start {
  flex: 20 1;
}

#main_section .mdc-top-app-bar__title {
  margin: 0 auto;
}

.mapContainer {
  height: calc(100vh - 64px);
}

#main-nav-drawer {
  opacity: 1;
}

.groupMenuDiv {
  margin-left: 14px !important;
  margin-right: 14px !important;
  border-color: #004691 !important;
}

.clientColor {
  color: #004691 !important;
}

.groupHeader {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
}

.mdc-list-item {
  font-family: "Open Sans", 'Roboto', serif !important;
}

.mdc-list-item.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.trackHistoryMenuDiv {
  margin-left: 14px !important;
  margin-right: 14px !important;
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mdl-color--primary {
  background-color: #004691 !important;
}

.mdc-select:not(.mdc-select--disabled),
.mdc-text-field:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

.mdc-select::before,
.mdc-select::after,
.mdc-text-field::before,
.mdc-text-field::after {
  background-color: white !important;
}

.mdc-text-field--fullwidth:not(.mdc-text-field--textarea)
  .mdc-text-field__input {
  padding-top: 12px !important;
}

.mdc-select .mdc-floating-label,
.mdc-text-field--fullwidth .mdc-floating-label {
  left: 0px !important;
}

.profileLetterIcon {
  text-align: center;
  font-size: 24px;
  color: white;
  font-family: 'Roboto', serif !important;
  line-height: 40px;
}

.activeMetaIcon {
  color: #004691 !important;
}

.menuGroup {
  //overflow:visible !important;
  z-index: 100;
}

.groupMenu2Div {
  border-color: #d8d8d8 !important;
}

.groupMenuOptionPos {
  margin-right: -12px;
}

.menuItem {
  color: #004691;
}

.menuSeparator {
  border-bottom: 1px solid #c8c8c8;
}

.noGroup {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80%;
  padding: 16px;
  text-align: center;
  color: #b2b2b2;
}

.contentFull {
  padding: 24px;
  max-width: 1230px;
  max-height: 85%;
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 28px;
}

.configDefault {
  align-items: center;
  justify-content: center;
  height: 90vh;
  display: flex;
}

.workDaysContainer {
  width: 288px;
  padding: 24px;
  margin: 0 auto;
  margin-top: 32px;
  background: white;
}

.workDays {
  width: 330px;
  margin-bottom: 16px;
}

.workDay {
  color: #004691;
  width: 40px;
  text-align: center;
  display: inline-block;
}

.workDayLabel {
  font-size: 12px;
  line-height: 12px;
  margin-top: -6px;
  color: #000;
}

.groupContainer {
  width: 1000px;
}

.contentCenter {
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  font-weight: bold;
  color: #004691;
  padding-top: 12px;
  padding-bottom: 12px;
}

.contentLeft {
  width: calc(50% - 32px);
  display: inline-block;
  vertical-align: top;
  padding-left: 16px;
  padding-right: 16px;
}

.contentRight {
  display: inline-block;
  width: calc(50% - 32px);
  vertical-align: top;
  padding-left: 16px;
  padding-right: 16px;
}

.contentTitle {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  color: #000;
}

.contentInfo {
  font-size: 12px;
  line-height: 28px;
  font-weight: bold;
  color: #777777;
  padding-bottom: 16px;
}

.groupMemberList {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.groupListDiv {
  line-height: 18px;
  text-align: left;
}

.groupListItem {
  color: #4d4d4d !important;
}

.phoneNumber {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}

.infoSubMenu {
  color: rgba(0, 0, 0, 0.4);
  font-size: 10px;
  margin-top: 18px;
  line-height: 14px;
}

.groupListSwitch {
  margin-left: 16px !important;
}

.userLevel {
  margin-left: auto;
  width: 27px;
  height: 8px;
  border-radius: 2px;
  font-size: 8px;
  text-transform: uppercase;
  padding: 2px;
  line-height: 8px;
}

.levelManager {
  border: 1px solid;
  color: #63ad0d;
  border-color: #63ad0d;
}

.levelAdmin {
  border: 1px solid;
  color: red;
  border-color: red;
}

.searchField .mdc-text-field__input {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.modalContent {
  background: white;
  width: 560px;
}

.modalCloseButton {
  color: #c2c2c2 !important;
  font-weight: bold;
}

.chatContainer {
  padding: 24px;
}

.configContainer {
  width: 420px;
  padding: 24px;
}

.configHeaderList {
  text-align: left;
  color: #000000;
  font-weight: bold;
}

.configHeaderSubText {
  font-size: 12px;
  color: #777;
  font-weight: normal;
}

.configHeaderMeta {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 18px;
}

.configHeaderMetaItem {
  margin-right: 8px !important;
  width: 40px !important;
}

.markerRadar {
  width: 40px;
  height: 40px;
}

.markerContent {
  width: 40px;
  height: 40px;
  background-size: cover;
  font-size: 20px;
  line-height: 40px;
  border-radius: 20px;
}

.contactPhotoDetail {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  display: block;
  background-size: contain;
  margin: 0 auto;
  line-height: 120px;
  background-color: #004691;
  color: white;
  font-size: 60px;
}

.contactNameDetail {
  margin-top: 24px;
  font-size: 24px;
  text-transform: capitalize;
  font-weight: normal;
}

.contactSubTextDetail {
  height: 16px;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 8px;
}

.contactSubTextOffline {
  height: 16px;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
  color: rgba(230, 20, 20, 1);
  margin-top: 8px;
}

.contactBatteryLevel {
  width: 54px;
  height: 54px;
  border-radius: 27px;
  display: inline-block;
  background-color: #f5a623;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  line-height: 73px;
}

.contactBatteryText {
  position: relative;
  top: -40px;
  font-size: 14px;
  left: 50px;
  font-weight: normal;
}

.giftedChatSend {
  margin-top: 8px;
  width: 118px;
  background: #004691;
  color: white;
  font-size: 18px;
  text-align: center;
}

.giftedChatSend div {
  padding: 0px !important;
  margin: 4px 8px !important;
}

.giftedChatComposer {
  width: 100%;
  height: 64px;
  border: 1px solid #979797;
  padding: 16px;
  margin-top: 0px;
}

.giftedChatToolbar {
  flex-direction: column;
}

.giftedChatMessageAuthor {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #63ad0d;
  margin: 10px;
  text-align: left;
  margin-bottom: -8px;
  margin-top: 2px;
}

.giftedChatMessageBubble {
  background-color: white !important;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #979797;
  padding-left: 19px;
  padding-right: 19px;
  width: 90%;
  margin-bottom: 5px;
}

.giftedChatMessageText {
  text-align: left;
  font-size: 14px;
  font-weight: normal;
}

.giftedChatMessageDate {
  font-weight: bold;
  border-radius: 8px;
  background-color: #d6e5f5;
  border-style: solid;
  border-width: 1px;
  border-color: #979797;
  width: 117px;
  color: #0b0b0b;
}

.giftedChatMessageTime {
  margin-top: -22px;
  height: 20px;
}

.giftedChatMessageDate div {
  color: #0b0b0b !important;
  font-size: 14px;
}

.lineTwoRow {
  color: blue;
  height: 160px !important;
}

.lineTwoRow li {
  line-height: 24px;
  list-style: none;
  cursor: pointer;
}

.activeKind {
  color: #004691;
}

.selecteableKind {
  color: #333333;
}

.configMemberContainer {
  width: 336px;
  padding: 24px;
  margin: 0 auto;
  margin-top: 32px;
  background: white;
}

.lineTwoRow i {
  height: 128px;
}

.lineTwoRow i svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.configBackIcon {
  padding: 0px;
  margin-right: 0px !important;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  width: 30px !important;
  margin-left: -20px !important;
}

.mdc-snackbar__action-button {
  color: lightblue !important;
}

.workDaysContainer .mdc-form-field {
  color: black;
  width: 100%;
}

.snoozeConfigUntil {
  padding-left: 40px;
  height: 33px;
  margin-top: -10px;
}

.snoozeConfigUntil .mdc-text-field {
  height: 33px;
  margin-bottom: 16px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1000px) {
  .configContainer {
    width: 340px;
  }

  .contentLeft,
  .contentRight {
    width: 340px;
  }

  .groupContainer {
    width: 376px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles */
  .app__content {
    float: right;
    width: calc(100% - 256px);
  }

  .menuAccount {
    justify-content: flex-start !important;
  }

  .menuAccount .modal {
    justify-content: flex-start !important;
  }

  i.mdc-top-app-bar__navigation-icon {
    display: none;
  }
}

.contactMapOffline {
  background-color: rgba(255, 10, 10, 1);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: relative;
  left: 30px;
  bottom: 10px;
}

.contactDetailsOffline {
  background-color: rgba(255, 10, 10, 1);
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: relative;
  left: 90px;
  bottom: 25px;
}

.contactDetailsOfflinePhoto {
  background-color: rgba(255, 10, 10, 1);
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: relative;
  left: 90px;
  bottom: -95px;
}

div > div > .undefined {
  overflow: visible !important;
  opacity: 1 !important;
}

.intervalCheckBox {
  display: flex;
}

.intervalText {
  padding-top: 8px;
}

